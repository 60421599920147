@import 'styles/_foundations.scss';
.Nav {
  position: fixed;
  top: spacing(4,4);
  right: spacing(4,4);

  padding: 0;
  z-index: 120;
  .MenuButton {
    position: absolute;
    top: spacing(4,3);
    left: spacing(4,3.2);
    width: spacing(4,2);
    height: 2px;
    background-color: color(black);
    transition: transition(fast);
    &:before, &:after {

      width: spacing(4,2);
      height: 2px;
      background-color: color(black);
      content: '';
      position: absolute;
      left: 0;
      top: spacing(4);
      transition: transition(fast);

    }
    &:after {
      top: spacing(4,2);
    }
  }
  a {
    &:hover {
      .MenuButton {
        background-color: color(red);
          &:before, &:after {
            background-color: color(red);
          }
      }
    }
  }

  &.--dark {
    .Button {
      a {
        border-color:  color(white);
        &:hover {
          border-color:  color(red);
        }
      }

    }
    .MenuButton {
      background-color: color(white);
        &:before, &:after {
          background-color: color(white);
        }
    }
  }

}
