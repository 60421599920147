@import 'styles/_foundations.scss';
.ContentImage {
  // display: none;
   position: absolute;
  padding: spacing(4,8);

  z-index: 0;
  .ContentImage__Element {
    background-color: color(white);
    padding: spacing(4,1);
    transition: transition();
    border-radius: spacing(1);
    font-size: 0;
    line-height: 0;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: color(black);
      content: '';
      opacity: .8;
      transition: transition();
    }
    img {
      max-width: 40vw;
      max-height: 80vh;
      object-fit: cover;
    }
  }
 &.--active {

   z-index: 10;
   .ContentImage__Element {
     box-shadow: 0 spacing(4,4) spacing(4,12) color(black);
       &:before {
         opacity: 0;
       }
   }
 }

}
