@import 'styles/_foundations.scss';
.TextMessage {
  .TextMessage__Element {
    font-size: 0;
    line-height: 0;
  }
  .TextMessage__Bubble {
    padding: 0;

    background-color: color(blue);
    display: inline-block;
    font-size: font-size();
    line-height: line-height();
    color: color(black);
    border-radius: 0 spacing(4,6) spacing(4,6) spacing(4,6);
    margin-bottom: spacing(1);
    max-width: 0vw;
    overflow: hidden;
    opacity: 0;
    max-height: 0;
    transition: transition();
    .Bubble__container {
      padding: spacing(4,4) spacing(4,5) spacing(4,4) spacing(4,4);
      min-width: 30vw;
      transition: transition();
      transition-delay: .3s;

    }
  }
  &.--active {
    .TextMessage__Bubble {
      max-width: 40vw;
      opacity: 1;
      max-height: 500px;
      .Bubble__container {
        min-width: 0vw;
      }
    }
    & + .TextMessage.--active {
      .TextMessage__Bubble {
        border-radius: 0 spacing(4,6) spacing(4,6) spacing(4,6);
      }
    }
  }

  &.--passed {
    .TextMessage__Bubble {
      border-radius: 0 spacing(4,6) spacing(4,6) 0;
    }
  }



}
