@import 'styles/_foundations.scss';
.home {
  .Hero {
    h1 {
      max-width: 980px;
      cursor: default;
      .highlight {
        &:hover {
          color: color(red);
          cursor: crosshair;
        }
      }
    }
    min-height: 100vh;
    position: relative;
    .Hero__actions {
      position: absolute;
      bottom: spacing(4,4);
      left: spacing(4,4);
    }
  }
}
