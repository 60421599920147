@import 'styles/_foundations.scss';
.CursorElement {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;

  .CursorElement__Content {
    background-color: color(white);
    padding: spacing(4,1);
    opacity: 0;
    transition: transition();
    border-radius: spacing(1);
    position: absolute;
    top: spacing(4,4);
    font-size: 0;
    img {
      max-width: 500px;
      max-height: 500px;
      object-fit: cover;
      display: block;

    }
  }
  &.--active {

    .CursorElement__Content{
      opacity: 1;
      top: 0;
    }
  }
}
