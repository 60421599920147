@import 'styles/_foundations.scss';
.ChatInput {
  position: absolute;
  pointer-events: none;
  z-index: 100;


  &.--dark {
    color: color(white);
  }
  &.--empty {
    .ChatInput__container {
      .ChatInput__content {
        bottom: -220px;
      }
    }
  }
  .ChatInput__container {
    height: calc(100vh - 80px);
    position: relative;
    pointer-events: none;
    width: 100vw;

    .ChatInput__content {

      transition: transition();
      position: absolute;

      padding-bottom: spacing(4,4);
      font-size: font-size();
      line-height: line-height();
      bottom: - spacing(4,4);
      left: 0;
      width: 100%;
      display: flex;
      pointer-events: all;
      .ChatInput__Avatar {
        width: spacing(4, 4);
        margin-right: spacing(4, 2);
        overflow: hidden;
      }
    }
  }
  .ChatInput__Button {
    display: inline-block;
    border: 2px solid color(white);
    padding: spacing(4,2) spacing(4,4);
    border-radius: spacing(4,4);
    margin: 0 spacing(2) 0 0;
    background-color: color(black);
    cursor: pointer;
    &:hover {
      background-color: color(white);
      color: color(black);
    }
  }
}
