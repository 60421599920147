@import 'styles/_foundations.scss';
.Avatar {
  .Avatar__circle {
    display: inline-block;
    width: spacing(4,4);
    height: spacing(4,4);
    border-radius: spacing(4, 2);
    background-color: color(white);
  }
  &.--bot{
    .Avatar__circle {
      background-color: color(blue);
    }
  }
 }
