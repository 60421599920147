@import 'styles/_foundations.scss';
.UserTurn {
  padding: spacing(4, 4) 0;
  display: flex;
  .UserTurn__Avatar {
    width: spacing(4, 4);
    margin-right: spacing(4, 2);
    overflow: hidden;
  }
  .UserTurn__Messages {
    .UserTurn__Message__Element {
      font-size: 0;
      line-height: 0;
    }
    .UserTurn__Message__Bubble {
      padding: 0;
      background-color: color(white);
      display: inline-block;
      font-size: font-size();
      line-height: line-height();
      color: color(black);
      border-radius: 0 spacing(4,6) spacing(4,6) spacing(4,6);
      margin-bottom: spacing(1);
      max-width: 0vw;
      overflow: hidden;
      opacity: 0;
      max-height: 0;
      transition: transition();
      .UserTurn__Message__Bubble__container {
        padding: spacing(4,4) spacing(4,5) spacing(4,4) spacing(4,4);
        min-width: 30vw;
        transition: transition();
        transition-delay: .3s;

      }
    }
  }

  &.--active {
    .UserTurn__Messages {
      .UserTurn__Message__Bubble {
        max-width: 40vw;
        opacity: 1;
        max-height: 500px;
        .UserTurn__Message__Bubble__container {
          min-width: 0vw;
        }
      }
    }
  }
}
