@import 'styles/_foundations.scss';
.Button {
   .__container, a{
     font-size: font-size();
     display: inline-block;
     padding: 0 spacing(4,4);
     text-decoration: none;
     border: 2px solid color(black);
     line-height: spacing(4,7.7);
     height: spacing(4,8);
     border-radius: spacing(4, 4);
     transition: transition(fast);
     &:hover {

         color: color(red);
         border-color: color(red);

     }
   }
  & + .Button {
    margin-top: spacing(2);
  }
 }
