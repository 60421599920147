@import '_foundations.scss';
* {
  box-sizing: border-box;
}
body, html {

  font-family: font-family();

  font-size: $basicFontSize;
  line-height: line-height();
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  text-align: left;
  &.dark {
    background-color: color(black);
  }
}

section {
  padding: spacing(4,4);

}
.page {
  transition: transition();
  min-height: 100vh;

}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:6em;
}



.App {
  color: color(black);
  background-color: color(white);
  transition: transition();
  position: absolute;
  top: 0;left: 0;
  width: 100vw;
  height: 100vh;

  main {
    z-index: 50;
    position: relative;
  }

  a {
    transition: transition();
    transition-delay: .8s;
    color: color(black);
    text-decoration: underline;
  }

  &.App-dark {
    background-color: color(black);
    color: color(white);
    a {
      color: color(white);

    }


  }


}

@import 'globals/_headings.scss';
@import 'globals/_paragraphs.scss';
