@import 'styles/_foundations.scss';
.Element {
  transition: all .5s ease-in-out;
  position: relative;
  opacity: 0;
  transition-delay: 0 !important;
  transition-duration: 0 !important;
  &.--visible {
    opacity: 1;
  }
  &.--inline {
    display: inline;
  }

  &.--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    a {
      pointer-events:all;
    }

  }

}
