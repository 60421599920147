@import 'styles/_foundations.scss';
.Chat {

  z-index: 10;
  min-height: 100vh;
  padding: spacing(4,20) 0 spacing(4,40);

  &.--dark {
    color: color(white);
  }
}
