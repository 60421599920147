@import 'styles/_foundations.scss';
.Turn {
  
  display: flex;
  .Turn__Avatar {
    width: spacing(4, 4);
    margin-right: spacing(4, 2);
    overflow: hidden;
  }
}
